// import { useParams } from "react-router-dom"
import { Header } from "../component/Header"
// import { useRo } from 'react-router-dom'

export default function Categories() {
    // const params = useParams()
    // const userId = params.categorie

    return (
        <>
        <Header />
        {/* <div>
            {userId && userId}
        </div> */}
        </>
    )
}
